import "./dashboard.css"

import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Euro';
import SettingsIcon from '@material-ui/icons/Settings';
import DailyProcessingRequestsChart from "../components/dailyProcessingRequestsChart/DailyProcessingRequestsChart";
import GlobalMetrics from "../components/globalMetrics/GlobalMetrics";

const Dashboard = () => {
    return(
        <div id="dashboard">
            <h2>Processing Requests</h2>
            <DailyProcessingRequestsChart resource="global-daily-metrics" dateField="date" grid />
            <div className="twoCards">
                <Card className="dashboardCard">
                    <CardHeader avatar={<MoneyIcon/>} title="Paid"/>
                    <CardContent>
                        <GlobalMetrics type={0} />
                    </CardContent>
                </Card>
                <Card className="dashboardCard">
                    <CardHeader avatar={<SettingsIcon/>} title="Trial"/>
                    <CardContent>
                        <GlobalMetrics type={1} />
                    </CardContent>
                </Card>
            </div>
        </div>
    )
};

export default Dashboard;