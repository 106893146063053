// in src/App.js
import React from 'react';
import { Resource } from 'react-admin';
import { HydraAdmin, ResourceGuesser } from '@api-platform/admin';
import { createMuiTheme } from '@material-ui/core/styles';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import i18nProvider from './providers/i18nProvider';
import CustomLayout from './layout/CustomLayout';
import CustomLogin from './layout/CustomLogin';
import Dashboard from './layout/Dashboard';

import UserIcon from '@material-ui/icons/People';
import CustomerIcon from '@material-ui/icons/Business';
import LicenseIcon from '@material-ui/icons/Subtitles';
import ProcessingRequestIcon from '@material-ui/icons/Storage';

import { UsersList, UsersShow, UsersCreate, UsersEdit } from "./resources/UsersGuessers";
import { CustomersList, CustomersShow, CustomersCreate, CustomersEdit } from "./resources/CustomersGuessers";
import { LicensesList, LicensesShow, LicensesCreate, LicensesEdit } from "./resources/LicensesGuessers";
import { ProcessingRequestsList, ProcessingRequestsShow, ProcessingRequestsCreate, ProcessingRequestsEdit }
    from "./resources/ProcessingRequestsGuessers";

const theme = createMuiTheme({
    palette: {
        type: 'light', // Switching the dark mode on is a single property value change.
    },
});

const App = () => (
    <HydraAdmin
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        i18nProvider={ i18nProvider }
        theme={ theme }
        layout={ CustomLayout }
        loginPage={ CustomLogin }
        dashboard={ Dashboard }
        disableTelemetry
    >
        <ResourceGuesser
            name="users"
            list={ UsersList }
            show={ UsersShow }
            create={ UsersCreate }
            edit={ UsersEdit }
            icon={ UserIcon }
        />
        <ResourceGuesser
            name="customers"
            list={ CustomersList }
            show={ CustomersShow }
            create={ CustomersCreate }
            edit={ CustomersEdit }
            icon={ CustomerIcon }
        />
        <ResourceGuesser
            name="licenses"
            list={ LicensesList }
            show={ LicensesShow }
            create={ LicensesCreate }
            edit={ LicensesEdit }
            icon={ LicenseIcon }
        />
        <ResourceGuesser
            name="processing-requests"
            list={ ProcessingRequestsList }
            show={ ProcessingRequestsShow }
            create={ ProcessingRequestsCreate }
            edit={ ProcessingRequestsEdit }
            options={{ label: 'Processing Requests'}}
            icon={ ProcessingRequestIcon }
        />
        <Resource name="global-daily-metrics"/>
        <Resource name="global-metrics"/>
        <Resource name="license-daily-metrics"/>
        <Resource name="customer-daily-metrics"/>
    </HydraAdmin>
);

export default App;