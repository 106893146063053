import "./dailyProcessingRequestsChart.css"

import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import {Error, Loading, useGetList, useRefresh} from 'react-admin';
import {subDays} from 'date-fns';
import React, {useEffect, useState} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DateRangeIcon from "@material-ui/icons/DateRange";
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';

const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString();
};

const formatDateShort = (dateStr) => {
    const parts = dateStr.split('.');
    const day = parts[0];
    const month = parts[1];
    return `${day}.${month}`;
};

const removeAllPaths = additionalFilters => {
    const encodedFilters = {};
    for (const key in additionalFilters) {
        const parts = additionalFilters[key].split('/');
        encodedFilters[key] = parts[parts.length - 1];
    }
    return encodedFilters;
};

export default function DailyProcessingRequestsChart({resource, dateField, grid, additionalFilters = {}}) {
    const refresh = useRefresh();
    const [selectedTimeRange, setSelectedTimeRange] = useState(7);

    const handleTimeRangeChange = (event) => {
        setSelectedTimeRange(event.target.value);
        // trigger a data reload using the useRefresh hook
        refresh();
    };

    // resizing
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const aspect = containerWidth >= 600 ? 5 : 2;

    const typeColors = {
        '0': '#8884d8', // custom color for Paid
        '1': '#d284d8', // custom color for Trial
    };

    const dayInThePast = subDays(new Date(), selectedTimeRange);

    const filters = {
        ...removeAllPaths(additionalFilters),
        'date[strictly_after]': dayInThePast.toISOString().split('T')[0],
        pagination: 'false'
    };

    const { data: prData, loading: prLoading, error: prError } = useGetList(
        resource,
        {},
        { field: dateField, order: 'ASC' },
        { ...filters }
    );

    if (prLoading) return <Loading />;
    if (prError) return <Error />;
    if (Object.keys(prData).length === 0) return null;

    const dataArray = Object.values(prData);
    // Create an object to group data by type
    const groupedData = dataArray.reduce((acc, entry) => {
        if (!acc[entry.type]) {
            acc[entry.type] = [];
        }
        acc[entry.type].push(entry);
        return acc;
    }, {});

    // find all unique dates across all types
    const allDates = new Set();
    Object.values(groupedData).forEach(typeData => {
        Object.values(typeData).forEach(date => {
            allDates.add(date.date);
        });
    });

    // check if there are more than one types (for performance reasons)
    if (Object.keys(groupedData).length > 1) {
        // Ensure both types have data for each date
        let dataPointAdded = false;
        allDates.forEach(date => {
            Object.keys(groupedData).forEach(type => {
                // check if there is an object in groupedData[type] with the current date
                const hasDate = groupedData[type].find(entry => entry.date === date);
                if (!hasDate) {
                    groupedData[type].push({
                        date,
                        processingRequestCount: 0,
                    });
                    dataPointAdded = true;
                }
            });
        });

        if (dataPointAdded) {
            // sort each type by date
            Object.keys(groupedData).forEach(type => {
                groupedData[type].sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateA - dateB;
                });
            });
        }
    }

    // set a variable if there is an additional filter
    const additionalFilterSet = Object.keys(additionalFilters).length > 0;

    const chartData = Object.keys(groupedData).map(type => ({
        type: additionalFilterSet ? 'Amount' : type === '0' ? 'Paid' : 'Trial',
        data: groupedData[type].map(entry => ({
            ...entry,
            date: formatDate(entry.date),
        })),
        color: typeColors[type],
    }));

    // set title to "Daily" if there are no additional filters and to "Daily Processing Requests" if there are
    let title = "Daily";
    if (additionalFilterSet) {
        title = "Daily Processing Requests";
    }

    return(
        <Card className="dashboardCard">
            <CardHeader avatar={<DateRangeIcon/>}
                        subheader={
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <span className="MuiTypography-root MuiCardHeader-title MuiTypography-body2 MuiTypography-displayBlock">{title}</span>
                                </Grid>
                                <Grid item>
                                    <FormControl className="time-range">
                                        <Select
                                            labelId="timeRangeLabel"
                                            id="timeRangeSelect"
                                            value={selectedTimeRange}
                                            label="Time Range"
                                            onChange={handleTimeRangeChange}
                                        >
                                            <MenuItem value={7}>7 days</MenuItem>
                                            <MenuItem value={14}>14 days</MenuItem>
                                            <MenuItem value={60}>60 days</MenuItem>
                                            <MenuItem value={90}>90 days</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        }
            />
            <CardContent>
                <div className="weeklyChart">
                    <ResponsiveContainer width="100%" aspect={aspect}>
                        <LineChart data={chartData}>
                            {grid && <CartesianGrid strokeDasharray="3 3" stroke="#dddddd" />}
                            <XAxis dataKey="date" interval="preserveStartEnd" tick={{fontSize: 12}} tickFormatter={formatDateShort} dy={10} stroke="#8884d8" allowDuplicatedCategory={false} />
                            {chartData.map((lineData) => (
                                <Line type="monotone" dataKey="processingRequestCount" name={lineData.type} data={lineData.data} stroke={lineData.color || '#8884d8'} strokeWidth={2} isAnimationActive={false} />
                            ))}
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}