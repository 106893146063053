import React from "react";
import {
    FieldGuesser,
    ListGuesser,
    ShowGuesser,
    CreateGuesser,
    EditGuesser,
    InputGuesser,
} from "@api-platform/admin";
import {
    ChipField,
    ReferenceField,
    TextField,
    BooleanField,
    ReferenceInput,
    AutocompleteInput,
    PasswordInput,
    required
} from 'react-admin';

const UsersList = (props) => (
    <ListGuesser {...props}  perPage={25}>
        <FieldGuesser source="email" />
        <FieldGuesser source="admin" />
        <ReferenceField label="Customer" reference="customers" source="customer" link="show" allowEmpty>
            <ChipField source="name" />
        </ReferenceField>
    </ListGuesser>
);

const UsersShow = props => (
    <ShowGuesser {...props}>
        <TextField label="Email" source="email" className="resourceShowTitle" />
        <BooleanField source="admin" />
        <ReferenceField label="Customer" reference="customers" source="customer" link="show" allowEmpty>
            <ChipField source="name" />
        </ReferenceField>
    </ShowGuesser>
);

const UsersCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="email" />
        <PasswordInput source="password" validate={[required()]} />
        <InputGuesser source="admin" />
        <ReferenceInput
            source="customer"
            reference="customers"
            label="Customer"
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

const UsersEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="email" />
        <PasswordInput source="password" />
        <InputGuesser source="admin" />
        <ReferenceInput
            source="customer"
            reference="customers"
            label="Customer"
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty={true}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);

export { UsersList, UsersShow, UsersCreate, UsersEdit };