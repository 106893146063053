import { hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra } from "@api-platform/admin";
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import authProvider from "./authProvider";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () =>
    localStorage.getItem("token")? { Authorization: `Bearer ${localStorage.getItem("token")}`} : {};
const fetchHydra = (url, options = {}) => {
    authProvider.logOutIfTokenExpired();
    return baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });
};
const apiDocumentationParser = entrypoint =>
    parseHydraDocumentation(entrypoint, {
        headers: new Headers(getHeaders())
    }).then(
        ({api}) => ({api}),
        result => {
            switch (result.status) {
                case 401:
                    authProvider.logoutAndRedirect();
                    return Promise.resolve(result);
                case 403:
                    authProvider.logoutAndRedirect();
                    return Promise.resolve(result);
                default:
                    return Promise.resolve(result);
            }
        }
    );
const dataProvider = baseHydraDataProvider({
    entrypoint,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser
});
export default dataProvider;
