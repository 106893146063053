import * as React from 'react';
import { AppBar } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import './CustomAppBar.css';

const CustomAppBar = props => (
    <AppBar {...props}>
        <Box flex="1">
            <Typography variant="h6">QStamper Admin <span id="react-admin-title"> </span></Typography>
        </Box>
    </AppBar>
);

export default CustomAppBar;
