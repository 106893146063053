import "./globalMetrics.css"

import { useGetList, Loading, Error } from 'react-admin';
import { subDays } from 'date-fns';

export default function GlobalMetrics({type}) {

    const { data: dataTotal, loading: loadingTotal, error: errorTotal } = useGetList(
        'global-metrics',
        {},
        {},
        {}
    );
    const yesterday = subDays(new Date(), 1);
    const { data: dataToday, loading: loadingToday, error: errorToday } = useGetList(
        'global-daily-metrics',
        {},
        { field: 'date', order: 'ASC' },
        { 'date[strictly_after]': yesterday.toISOString().split('T')[0], pagination: 'false', type: type }
    );

    if (loadingTotal || loadingToday) return <Loading />;
    if (errorTotal || errorToday) return <Error />;
    if (!dataTotal) return null;

    // get first object from data
    const dataArray = Object.values(dataTotal);
    const dataObject = dataArray[0];

    let processingRequestCount;
    if (type === 0) {
        processingRequestCount = dataObject.processingRequestCountDefault;
    } else {
        processingRequestCount = dataObject.processingRequestCountTrial;
    }
    processingRequestCount = new Intl.NumberFormat().format(processingRequestCount);

    // get first object from dataToday
    let processingRequestCountToday = 0;
    if (dataToday) {
        const dataArrayToday = Object.values(dataToday);
        const dataObjectToday = dataArrayToday[0];
        if (dataObjectToday) {
            processingRequestCountToday = dataObjectToday.processingRequestCount;
        }
    }
    processingRequestCountToday = new Intl.NumberFormat().format(processingRequestCountToday);

    return(
        <div className="globalMetrics">
            <div className="total">
                <p className="count">{processingRequestCount}</p>
                <p className="desc">Total</p>
            </div>
            <div className="today">
                <p className="count">{processingRequestCountToday}</p>
                <p className="desc">Today</p>
            </div>
        </div>
    )
}