import { Login, LoginForm } from 'react-admin';
import * as React from "react";
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: {
        background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
        backgroundSize: '400% 400%',
        animation: '$gradient 15s ease infinite',
    },
    '@keyframes gradient': {
        '0%': {
            backgroundPosition: '0% 50%'
        },
        '50%': {
            backgroundPosition: '100% 50%'
        },
        '100%': {
            backgroundPosition: '0% 50%'
        }
    }
});

const CustomLoginForm = (props) => (
    <LoginForm {...props} />
);

const CustomLogin = (props) => (
    <Login loginForm={<CustomLoginForm />} {...props} />
);

export default withStyles(styles)(CustomLogin);