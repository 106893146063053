import React from "react";
import {
    CreateGuesser, EditGuesser,
    FieldGuesser, InputGuesser,
    ListGuesser, ShowGuesser,
} from "@api-platform/admin";
import {
    ChipField,
    ReferenceField,
    BooleanField,
    TextField,
    DateField,
    NumberField,
    ReferenceInput,
    AutocompleteInput,
    required,
    Filter,
    BooleanInput
} from 'react-admin';
import DailyProcessingRequestsChart from "../components/dailyProcessingRequestsChart/DailyProcessingRequestsChart";

const DefaultFilters = (props) => (
    <Filter {...props}>
        <BooleanInput label="Only active" source="isActive" alwaysOn parse={ v => v ? true : '' } />
        <BooleanInput label="Hide trials" source="isTrial" alwaysOn parse={ v => v ? false : '' }
                      format={ v => v === false } />
    </Filter>
);

const LicensesList = (props) => (
    <ListGuesser {...props} sort={{field: 'createdAt', order: 'DESC'}} perPage={25} filters={<DefaultFilters />}
                 filterDefaultValues={{ isActive: true, isTrial: '' }}>
        <FieldGuesser source="title"/>
        <ReferenceField label="Customer" reference="customers" source="customer" link="show">
            <ChipField source="name"/>
        </ReferenceField>
        <DateField label="Created at" source="createdAt" showTime={true}/>
        <FieldGuesser source="isActive" label="Active"/>
        <FieldGuesser source="isTrial" label="Trial"/>
        <FieldGuesser source="usageLimit" label="Limit"/>
        <FieldGuesser source="usageCount" label="Usage"/>
    </ListGuesser>
);

const LicensesShow = props => (
    <div style={{paddingBottom: '3rem'}}>
        <div style={{marginBottom: '3rem'}}>
            <ShowGuesser {...props}>
                <TextField label="Title" source="title" className="resourceShowTitle"/>
                <DateField label="Created at" source="createdAt" showTime={true}/>
                <ReferenceField label="Customer" reference="customers" source="customer" link="show" allowEmpty>
                    <ChipField source="name"/>
                </ReferenceField>
                <BooleanField label="Active" source="isActive"/>
                <BooleanField label="Trial" source="isTrial"/>
                <NumberField label="Usage limit" source="usageLimit" />
                <NumberField label="Usage" source="usageCount" />
                <TextField component="pre" label="License Key" source="licenseKey" />
            </ShowGuesser>
        </div>
        <div>
            <DailyProcessingRequestsChart resource="license-daily-metrics" dateField="date"
                                      additionalFilters={{ 'license.uuid': props.id }}
                                      grid />
        </div>
    </div>
);

const LicensesCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="title" />
        <InputGuesser source="usageLimit" />
        <InputGuesser source="isActive" label="Active" />
        <InputGuesser source="isTrial" label="Trial" />
        <ReferenceInput
            source="customer"
            reference="customers"
            label="Customer"
            filterToQuery={searchText => ({ name: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

const LicensesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="title" />
        <InputGuesser source="usageLimit" />
        <InputGuesser source="isActive" label="Active" />
        <InputGuesser source="isTrial" label="Trial" />
        <ReferenceInput
            source="customer"
            reference="customers"
            label="Customer"
            filterToQuery={searchText => ({ name: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);

export { LicensesList, LicensesShow, LicensesCreate, LicensesEdit };