import decodeJwt from 'jwt-decode';

// Change this to be your own login check route.
const login_uri = process.env.REACT_APP_API_ENTRYPOINT + "/authentication-token";

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${login_uri}`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem('token', token);
                if(authProvider.isAdmin()) {
                    // reload
                    window.location.replace('/');
                } else {
                    authProvider.logoutAndRedirect();
                }
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            authProvider.logoutAndRedirect();
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve(error);
    },
    checkAuth: () => {
        authProvider.logOutIfTokenExpired();

        const isAdmin = authProvider.isAdmin();
        return isAdmin ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    getIdentity: () => { /* ... */ },
    getPermissions: () => {
        const roles = authProvider.decodePermissions();
        return roles ? Promise.resolve(roles) : Promise.reject();
    },
    decodePermissions: () => {
        if (localStorage.getItem('token') === null) {
            return Promise.reject();
        }
        try {
            const decodedToken = decodeJwt(localStorage.getItem('token'));
            return decodedToken.roles;
        } catch {
            return null;
        }
    },
    isAdmin: () => {
        if (localStorage.getItem('token') === null) {
            return false;
        }

        const roles = authProvider.decodePermissions();
        return !!(roles !== null && roles.includes('ROLE_ADMIN'));
    },
    logoutAndRedirect: () => {
        localStorage.removeItem('token');
        window.location.replace('/#/login');
    },
    logOutIfTokenExpired: () => {
        if (localStorage.getItem('token') !== null) {
            try {
                const decoded = decodeJwt(localStorage.getItem('token'));
                const now = Date.now().valueOf() / 1000;
                // expired
                if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
                    authProvider.logoutAndRedirect();
                }
                // token not valid yet
                if (typeof decoded.iat !== 'undefined' && decoded.iat > now + 1000) {
                    authProvider.logoutAndRedirect();
                }
            } catch {
                authProvider.logoutAndRedirect();
            }
        }
    },
};

export default authProvider;