import React from "react";
import {
    FieldGuesser,
    ListGuesser,
    ShowGuesser,
    CreateGuesser,
    EditGuesser,
    InputGuesser,
} from "@api-platform/admin";
import {
    ChipField,
    ReferenceManyField,
    SingleFieldList,
    TextField,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput
} from 'react-admin';
import DailyProcessingRequestsChart from "../components/dailyProcessingRequestsChart/DailyProcessingRequestsChart";

const CustomersList = (props) => (
    <ListGuesser {...props}  perPage={25}>
        <FieldGuesser source="name" />
    </ListGuesser>
);

const CustomersShow = props => (
    <div style={{ paddingBottom: '3rem' }}>
        <div style={{ marginBottom: '3rem' }}>
            <ShowGuesser {...props}>
                <TextField label="Name" source="name" className="resourceShowTitle" />
                <ReferenceManyField label="Users" reference="users" target="customer" allowEmpty>
                    <SingleFieldList linkType="show">
                        <ChipField source="email" />
                    </SingleFieldList>
                </ReferenceManyField>
                <ReferenceManyField label="Licenses" reference="licenses" target="customer" allowEmpty>
                    <SingleFieldList linkType="show">
                        <ChipField source="title" />
                    </SingleFieldList>
                </ReferenceManyField>
            </ShowGuesser>
        </div>
        <div>
            <DailyProcessingRequestsChart resource="customer-daily-metrics" dateField="date"
                                          additionalFilters={{ 'customer': props.id }}
                                          grid />
        </div>
    </div>
);

const CustomersCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <ReferenceInput
            source="users"
            reference="users"
            label="Users"
            filterToQuery={searchText => ({ email: searchText })}
            allowEmpty={true}
        >
            <SelectArrayInput optionText="email" />
        </ReferenceInput>
        <ReferenceInput
            source="licenses"
            reference="licenses"
            label="Licenses"
            filterToQuery={searchText => ({ title: searchText })}
            allowEmpty={true}
        >
            <SelectArrayInput optionText="title" />
        </ReferenceInput>
    </CreateGuesser>
);

const CustomersEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <ReferenceArrayInput
            source="users"
            reference="users"
            label="Users"
            allowEmpty={true}
        >
            <SelectArrayInput  optionText="email" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="licenses"
            reference="licenses"
            label="Licenses"
            allowEmpty={true}
        >
            <SelectArrayInput  optionText="title" />
        </ReferenceArrayInput>
    </EditGuesser>
);


export { CustomersList, CustomersShow, CustomersCreate, CustomersEdit };