import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import {domainMessages} from "../i18n/domainMessages";

// overrides
englishMessages.ra.auth.username = "Email";

// languages
const messages = {
    en: { ...englishMessages, ...domainMessages.en}
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale]);

export default i18nProvider;