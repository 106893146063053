import React from "react";
import {
    CreateGuesser,
    EditGuesser,
    InputGuesser,
    ListGuesser,
    ShowGuesser,
} from "@api-platform/admin";
import {
    ChipField,
    ReferenceField,
    DateField,
    TextField,
    ReferenceInput,
    AutocompleteInput,
    required,
    ReferenceFieldController
} from 'react-admin';
import './ProcessingRequestsGuesser.css';


const ProcessingRequestsList = (props) => (
    <ListGuesser {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25}>
        <DateField label="Created at" source="createdAt" showTime={ true } />
        <ReferenceFieldController label="Customer" reference="licenses" source="license" link={false}>
            {({referenceRecord}) => (
                <ReferenceField resource="license" reference="customers" source="customer" record={referenceRecord || {}} link="show">
                    <ChipField source="name" />
                </ReferenceField>
            )}
        </ReferenceFieldController>
        <ReferenceField label="License" reference="licenses" link="show" source="license">
            <ChipField source="title" />
        </ReferenceField>
    </ListGuesser>
);

const ProcessingRequestsShow = props => (
    <ShowGuesser {...props}>
        <DateField label="Created at" source="createdAt" showTime={ true } />
        <ReferenceField label="License" reference="licenses" source="license" link="show" allowEmpty>
            <ChipField source="title" />
        </ReferenceField>
        <TextField label="Challenge" source="challenge" />
        <TextField label="Challenge Response" source="challengeResponse" compontent="p"
                   id="Text-Field-challenge-response" />
    </ShowGuesser>
);

const ProcessingRequestsCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="license"
            reference="licenses"
            label="License"
            filterToQuery={searchText => ({ title: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <InputGuesser source="challenge" />
    </CreateGuesser>
);

const ProcessingRequestsEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="license"
            reference="licenses"
            label="License"
            filterToQuery={searchText => ({ title: searchText })}
            validate={[required()]}
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <InputGuesser source="challenge" />
    </EditGuesser>
);

export { ProcessingRequestsList, ProcessingRequestsShow, ProcessingRequestsCreate, ProcessingRequestsEdit };